:root {
  --primary-color: #8ed;
}
.ant-steps-icon {
  color: var(--primary-color) !important;
}
.ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: var(--primary-color) !important;
}
.ant-steps-navigation .ant-steps-item::before {
  background-color: var(--primary-color);
}
.ant-steps-navigation
  .ant-steps-item-container:hover
  .ant-steps-item-content
  > * {
  color: var(--primary-color) !important;
}
