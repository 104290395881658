    .pdf-page {
        position: relative;
        margin: 0 auto;
        padding: .4in .5in;
        color: #333;
        background-color: #fff;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .3);
        box-sizing: border-box;
        /*
          Use the DejaVu Sans font for display and embedding in the PDF file.
          The standard PDF fonts have no support for Unicode characters.
      */
        font-family: "DejaVu Sans", "Arial", sans-serif;
    }
    /*
      The example loads the DejaVu Sans from the Kendo UI CDN.
      Other fonts have to be hosted from your application.
      The official site of the Deja Vu Fonts project is
      https://dejavu-fonts.github.io/.
  */
    
    @font-face {
        font-family: "DejaVu Sans";
        src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
    }
    
    @font-face {
        font-family: "DejaVu Sans";
        font-weight: bold;
        src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
    }
    
    @font-face {
        font-family: "DejaVu Sans";
        font-style: italic;
        src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
    }
    
    @font-face {
        font-family: "DejaVu Sans";
        font-weight: bold;
        font-style: italic;
        src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
    }
    
    .pdf-header {
        padding-bottom: 0;
        margin-bottom: .3in;
        /* border-bottom: 1px solid #e5e5e5; */
    }
    
    .invoice-number {
        padding-top: .1in;
        float: right;
    }
    
    .pdf-footer {
        position: absolute;
        bottom: .25in;
        left: .3in;
        right: .3in;
        padding-top: 0.05in;
        border-top: 1px;
        font-size: 0.9em;
        text-align: left;
    }
    
    .addresses {
        font-size: 12px;
    }
    
    .addresses:after {
        display: block;
        content: "";
        clear: both;
    }
    
    .for {
        float: left;
    }
    
    .from {
        float: right;
    }
    
    .addresses p {
        color: #787878;
        padding: .05in 0;
        border-top: 1px solid #e5e5e5;
    }
    
    .addresses p:first-of-type {
        border-top: 0;
    }
    
    .pdf-body {
        margin-top: .3in;
    }
    
    .company-logo {
        font-size: 1.8em;
        font-weight: bold;
        color: #3aabf0;
    }
    
    .header {
        height: 140px;
        width: 750px;
        align-self: center;
        align-items: center;
        align-content: center;
    }
    
    .qr {
        height: 110px;
        width: 110px;
        float: right
    }
    
    .signature {
        padding-top: .3in;
    }
    /* Dimensions other than px and em should be divided by 1.33 for the proper PDF output */
    
    .size-a4 {
        width: 6.2in;
        height: 8.7in;
    }
    
    .size-letter {
        width: 6.3in;
        height: 8.2in;
    }
    
    .size-executive {
        width: 5.4in;
        height: 7.8in;
        font-size: 12px;
    }
    
    .size-executive .pdf-header {
        margin-bottom: .1in;
    }
    
    .box {
        padding: 1em;
        background-color: rgba(20, 53, 80, 0.038);
        border: 1px solid rgba(20, 53, 80, 0.05);
    }
    
    .grid-container-actos {
        display: grid;
        grid-template-columns: 1.5fr 0.5fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas: "actos-1 actos-2";
    }
    
    .actos-1 {
        grid-area: actos-1;
    }
    
    .actos-2 {
        grid-area: actos-2;
    }
    /* TABLA DE ACTOS */
    
    table.tableizer-table {
        line-height: 12pt
    }
    
    .tableizer-table td {
        height: 5px;
        font-size: 11pt;
    }
    
    .actos-table-titulo th {
        height: 5px;
        text-align: left;
        vertical-align: middle;
        font-size: 10pt;
        color: #787878;
    }
    /* TABLA DERECHOS NOTARIALES */
    
    table.tableizer-table {
        line-height: 15pt;
        width: 100%;
        border: 1px solid #ccc;
    }
    
    .tableizer-table td {
        height: 5px;
        font-size: 11pt;
        vertical-align: middle;
    }
    
    .total {
        height: 20px;
        font-size: 12pt;
        font-weight: bold;
        float: right;
        vertical-align: bottom;
        padding-right: 20pt;
    }
    
    .total-numero {
        height: 20px;
        font-size: 12pt;
        font-weight: bold;
    }
    
    .tableizer-table th {
        height: 5px;
        text-align: center;
        vertical-align: middle;
        font-size: 10pt;
        font-size: 10pt;
        background-color: #e6e3e3;
        border: 1px solid #ccc;
    }
    
    .col1 {
        width: 60%;
        text-align: right;
    }
    
    .col2 {
        width: 13.3%;
        text-align: right;
    }
    
    .col3 {
        width: 13.3%;
    }
    
    .col4 {
        width: 13.3%;
    }
    /* TABLA PARA SUBTOTALES */
    
    table.tabla-subtotal {
        line-height: 15pt;
        width: 100%;
    }
    
    .tabla-subtotal td {
        height: 5px;
        font-size: 11pt;
        vertical-align: middle;
    }
    
    .subtotal {
        height: 20px;
        font-size: 12pt;
        font-weight: normal;
        float: right;
        vertical-align: bottom;
        padding-right: 20pt;
    }
    
    .subtotal-numero {
        height: 15px;
        font-size: 11pt;
        font-weight: normal;
    }
    
    .tabla-subtotal th {
        height: 5px;
        text-align: left;
        vertical-align: middle;
        font-size: 10pt;
        color: #787878;
    }
    /* TABLA TOTALES */
    
    table.tabla-total {
        line-height: 15pt;
        width: 100%;
    }
    
    .tabla-total td {
        height: 5px;
        font-size: 11pt;
        vertical-align: middle;
    }
    
    .total {
        height: 20px;
        font-size: 12pt;
        font-weight: bold;
        float: right;
        vertical-align: bottom;
        padding-right: 20pt;
    }
    
    .total-numero {
        height: 20px;
        font-size: 12pt;
        font-weight: bold;
    }
    
    .tabla-total th {
        height: 5px;
        text-align: left;
        vertical-align: middle;
        font-size: 10pt;
        color: #787878;
    }
    
    .valor {
        text-align: right;
    }